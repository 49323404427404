@value grey-cool, white-full, black-full, black-dark from "styles/share/colors.css";
@value small, medium, small-height from "styles/share/bp.css";

.container {
  background-color: white-full;
  display: flex;
  justify-content: space-between;
}

.music_container {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.video_container {
  float: right;
  display: inline-block;
}

.title {
  margin-left: 15px;
}

.song {
  margin-bottom: 15px;
}

.text__description {
  font-size: 13px;
}

.text__title {
  font-weight: bold;
}

.button__song {
  text-align: start;
}

.button__song_active {
  color: blueviolet;
}

.button__song:hover {
  color: #333;
}

.filters__container {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
}

.button__filter {
  background-color: grey-cool;
  padding: 12px 16px;
  margin: 3px;
}

.button__filter_active {
  color: white;
}

.button__filter:disabled {
  display: none;
}

@media small {
  .container {
    flex-direction: column;
  }
}
