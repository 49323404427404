@value collapse-height: 400px;

/* @import '../../styles/share/typography.css'; */

.collapse__container {
  max-height: 0;
  transition: max-height 0.35s ease-out;
  overflow: hidden;
  height: auto;
}

.collapse__body {
  overflow: auto;
  max-height: collapse-height;

  /* composes: Text-Style from '../../styles/share/typography.css'; */

  /* font-family: 'Titillium Ru Bold', fantasy; */
}

.collapse_shown {
  max-height: collapse-height;
}

.collapse_hide {
  max-height: 0;
}
