@value link-hover from "./share/colors.css";

.button__empty {
  background: none;
  border: none;
  cursor: pointer;
}

.button__empty:focus {
  outline: none;
}

.button__link:hover {
  color: link-hover;
}
