* {
  box-sizing: border-box;
}
body {
  font-family: 'Lato', sans-serif;
}
.circle-media-player {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 0;
  background-color: transparent;
  fill: none;
  stroke: #d7dbdc;
  stroke-width: 3;
  cursor: pointer;
  outline: 0;
}
.circle-media-player__foreground {
  stroke: #6e9541;
  transition: 350ms stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.circle-media-player__play,
.circle-media-player__pause {
  fill: #6e9541;
  stroke-width: 0;
}

.media-player {
  width: 100%;
  max-width: 640px;
  position: relative;
}
.media-player video::-webkit-media-controls {
  display: none !important;
}
.media-player-element {
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
  background-color: #282f31;
}
.media-player-element video,
.media-player-element iframe,
.media-player-element object,
.media-player-element embed {
  display: block;
  width: 100%;
  height: 300%;
  border: 0;
  position: absolute;
  top: -100%;
  left: 0;
  pointer-events: none;
}
.video-controls {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: #282f31;
  color: #fff;
  padding-top: 5px;
}

.media-controls-inner {
  display: flex;
  align-items: center;
  background-color: #282f31;
  color: #fff;
}
.media-controls svg,
.media-controls path,
.media-controls polygon {
  transform-origin: 50% 50%;
}
.media-player--fullscreen .media-controls {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2147483647;
}
.media-controls--full {
  flex-direction: column;
}
.media-controls--full .media-control-group--seek {
  width: 100%;
  margin: 12px 0;
}
.media-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.media-control-group {
  display: flex;
  align-items: center;
  position: relative;
}
.media-control-group--seek {
  flex: 1;
}
.media-control {
  margin: 0 12px;
  cursor: pointer;
}
.media-control--progress {
  -webkit-appearance: none;
  width: calc(100% - 24px);
  height: 3px;
  margin: 0 12px;
  border: 0;
  position: absolute;
  top: 5px;
  left: 12px;
  background-color: #373d3f;
  color: #434a4d;
}

.media-control--current-time {
  align-self: center;
}

.media-control--duration {
  align-self: center;
}

.media-control--progress::-webkit-progress-bar {
  background-color: #373d3f;
}
.media-control--progress::-moz-progress-bar {
  background-color: #434a4d;
}
.media-control--progress::-webkit-progress-value {
  background-color: #434a4d;
}
.media-control--seekbar {
  position: relative;
  z-index: 5;
}
.media-control--seekbar::-webkit-slider-runnable-track {
  background-color: transparent !important;
}
.media-control--seekbar::-moz-range-track {
  background-color: transparent !important;
}
.media-control--seekbar::-ms-track {
  background-color: transparent !important;
}
.media-control--volume {
  max-width: 120px;
}
.media-control--prev-track {
  margin-right: 6px;
}
.media-control--next-track {
  margin-left: 6px;
}
input[type='range'] {
  width: 100%;
  height: 12px;
  padding: 0 12px;
  margin: 0;
  background-color: transparent;
}
input[type='range']:not(*:root) {
  overflow: hidden;
}
input[type='range']:not(*:root),
input[type='range']:not(*:root)::before,
input[type='range']:not(*:root)::after {
  box-sizing: border-box;
}

@supports (-moz-appearance: meterbar) {
  input[type='range'],
  input[type='range']::before,
  input[type='range']::after {
    box-sizing: border-box;
  }
}
_:-ms-input-placeholder,
:root {
  overflow: hidden;
}
_:-ms-input-placeholder,
:root,
_:-ms-input-placeholder::before,
:root ::before,
_:-ms-input-placeholder::after,
:root ::after {
  box-sizing: border-box;
}
input[type='range']:not(*:root) {
  font-size: 1em;
}
@supports (-moz-appearance: meterbar) {
}
_:-ms-input-placeholder,
:root {
  font-size: 1em;
}
input[type='range']:not(*:root) {
  -webkit-appearance: none;
}
input[type='range']:not(*:root):focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  position: relative;
  z-index: 1;
}
input[type='range']::-webkit-slider-runnable-track::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  z-index: -1;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  position: relative;
  z-index: -1;
}
input[type='range']::-webkit-slider-thumb::before {
  content: '';
  transform: translateX(-100%);
  display: block;
  z-index: -1;
}
input[type='range']::-moz-range-track {
  z-index: -1;
}
input[type='range']::-moz-focus-outer {
  border: 0;
}
input[type='range']::-ms-track {
  border: none;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: transparent;
}
input[type='range']::-ms-tooltip {
  display: none;
}
input[type='range']:hover::-webkit-slider-thumb,
input[type='range']:active::-webkit-slider-thumb {
  transform: scale(1.25);
}
input[type='range']:hover::-moz-range-thumb,
input[type='range']:active::-moz-range-thumb {
  transform: scale(1.25);
}
input[type='range']:hover::-ms-thumb,
input[type='range']:active::-ms-thumb {
  transform: scale(1.25);
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  border: 0;
  cursor: pointer;
  background-color: #373d3f;
}
input[type='range']::-webkit-slider-runnable-track:active {
  cursor: grabbing;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 3px;
  border: 0;
  cursor: pointer;
  background-color: #373d3f;
}
input[type='range']::-moz-range-track:active {
  cursor: grabbing;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 3px;
  border: 0;
  cursor: pointer;
  background-color: #373d3f;
}
input[type='range']::-ms-track:active {
  cursor: grabbing;
}
input[type='range']::-webkit-slider-container {
  background-size: inherit;
}
input[type='range']::-webkit-slider-runnable-track {
  background-image: linear-gradient(#86b350, #86b350);
  background-size: inherit;
  background-repeat: no-repeat;
}
input[type='range']::-webkit-slider-thumb::before {
  background-color: #86b350;
}
input[type='range']::-moz-range-progress {
  background-color: #86b350;
}
input[type='range']::-ms-fill-lower {
  background-color: #86b350;
}
input[type='range']::-webkit-slider-thumb {
  width: 8px;
  height: 8px;
  border: 0;
  border-radius: 50%;
  background-color: #cdd7db;
  cursor: grab;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 150ms ease-out;
}
input[type='range']::-webkit-slider-thumb:active {
  cursor: grabbing;
}
input[type='range']::-moz-range-thumb {
  width: 8px;
  height: 8px;
  border: 0;
  border-radius: 50%;
  background-color: #cdd7db;
  cursor: grab;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 150ms ease-out;
}
input[type='range']::-moz-range-thumb:active {
  cursor: grabbing;
}
input[type='range']::-ms-thumb {
  width: 8px;
  height: 8px;
  border: 0;
  border-radius: 50%;
  background-color: #cdd7db;
  cursor: grab;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 150ms ease-out;
}
input[type='range']::-ms-thumb:active {
  cursor: grabbing;
}
input[type='range']::-webkit-slider-thumb {
  position: relative;
  top: -2.5px;
}

.media-player-col-container {
  width: 100%;
}

.media-player-title-text {
  text-align: center;
}

.media-playlist-button {
  cursor: pointer;
}

.media-playlist-button:focus {
  outline: none;
}

.audio-container:focus {
  outline: none;
}

.media-controls {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: #282f31;
  color: #fff;
  min-height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 5000;
}

@value small, medium, large from "styles/share/bp.css";

.text__listen {
  display: none;
}

.button__close {
  font-size: 30px;
  color: white;
  order: 12;
}

.media-control__stop {
  display: none;
}

.button__close:focus {
  outline: none;
}

.media_hide {
  display: none;
}

@media small {
  .media-control--volume {
    display: none;
  }

  .media-control--current-time {
    display: none;
  }

  .media-control--mute-unmute {
    display: none;
  }

  .media-control--duration {
    display: none;
  }

  .media-control--play-pause {
    height: 40px;
    width: 40px;
  }

  .media-control--next-track {
    order: 11;
    height: 40px;
    width: 40px;
    padding: 10px;
  }

  .media-control--prev-track {
    display: none;
    height: 20px;
    width: 20px;
  }

  .button__close_hide {
    display: none;
  }

  .text__listen {
    display: block;
    flex-grow: 1;
    text-align: center;
    font-family: 'Forum', sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 1em;
    letter-spacing: 0.08333333333333333em;
    /* margin-right: 30px; */
  }

  .media-control__stop_show {
    display: block;
    order: 12;
    padding: 10px;
    width: 40px;
    height: 40px;
  }

  .media-control__stop-inside {
    width: 20px;
    height: 20px;
    background: white;
  }

  .media-control__stop:focus {
    outline: none;
  }
}
