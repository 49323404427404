@value grey-cool, dark, white-full from "styles/share/colors.css";
@value small, medium, small-height from "styles/share/bp.css";
@value header-width: 27vw;
@value jumbotron-height: 60px;

.wrapper {
  height: 100vh;
  overflow-x: hidden;
  background-color: grey-cool;
}

.header {
  background-color: grey-cool;
  position: fixed;
  width: header-width;
  height: 100vh;
  z-index: 8;
  /* overflow-y: auto; */
}

.main {
  margin-left: header-width;
  width: calc(100% - header-width);
  z-index: 8;
  position: absolute;
  background-color: grey-cool;
}

.main_content {
  margin-top: jumbotron-height;
}

.jumbo {
  z-index: 11;
  background-color: grey-cool;
  position: fixed;
  margin-left: header-width;
  width: calc(100vw - header-width);
  height: jumbotron-height;
}

.player {
  z-index: 11;
  position: sticky;
  bottom: 0;
}

.content-margin {
  height: 60px;
}

.menu__button {
  composes: button__empty button__link from global;
  z-index: 30;
  width: 50px;
  height: 50px;
  position: fixed;
  padding: 0;
  display: none;
  fill: white-full;
  background-color: dark;
}

@media medium, small {
  .menu__button {
    display: block;
  }

  .main {
    /* margin-left: 0; */
    /* transition: margin 0.5s; */
    width: 100%;
  }

  .jumbo {
    margin-left: 0;
    width: 100%;
    height: 50px;
  }

  .main_content {
    margin-top: 50px;
  }

  .header {
    z-index: 15;
    width: 30vw;
    transition: 0.5s;
  }

  .header_show {
    left: 0;
  }

  .header_hide {
    left: -45vw;
  }

  .main_full {
    margin-left: 0;
  }

  .main_part {
    margin-left: 0;
  }
}

@media small {
  .header {
    width: 200px;
    transition: 0.5s;
  }

  .header_show {
    left: 0;
  }

  .header_hide {
    margin-left: calc(50px - 200px);
  }

  /* .main_part {
    margin-left: 0;
  } */
}
