@value white-full, black-dark, grey-cool from "styles/share/colors.css";
@value small, medium, large from "styles/share/bp.css";

.container_main {
  display: none;
}

.text {
  font-family: 'Forum', sans-serif;
  font-size: 36px;
  font-weight: 400;
  font-style: normal;
  line-height: 1em;
  letter-spacing: 0.08333333333333333em;
  font-stretch: normal;
  color: black-dark;
  word-spacing: 100vw;
  width: 200px;
}

.text__name {
  font-size: 24px;
  letter-spacing: 3.6px;
  color: black-dark;
  text-align: end;
  float: right;
  /* left: 300px; */
}

.text__about {
  font-size: 12px;
  letter-spacing: 1.8px;
  color: white-full;
  margin-top: 0.4em;
  float: right;
  margin-right: calc(50% - 200px);
}

.text__title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  font-size: 18px;
  letter-spacing: 0.3em;
  position: absolute;
  bottom: 10px;
  word-spacing: initial;
}

@media medium {
  .text__title {
    left: 50px;
    bottom: 0;
  }
}

@media small {
  .container_main {
    display: block;
  }

  .text__about {
    display: none;
  }

  .text__name {
    /* text-align: center; */
    margin-right: 50px;
    width: 100%;
    line-height: 20px;
  }

  .text__title {
    left: 50px;
    /* bottom: 0px; */
    bottom: 0;
    /* top: 26px; */
    line-height: 22px;
    font-size: 16px;
  }
}
