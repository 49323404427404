@value white-full, black-dark from "styles/share/colors.css";
@value small, medium, large from "styles/share/bp.css";

.image {
  /* composes: grid__main from global; */
  width: 100%;
  object-fit: cover;
  height: 100vh;
}

.text {
  font-family: 'Forum', sans-serif;
  font-size: 36px;
  font-weight: 400;
  font-style: normal;
  line-height: 1em;
  letter-spacing: 0.08333333333333333em;
  /* font-weight: normal; */
  font-stretch: normal;
  /* line-height: 1; */
  color: black-dark;
  /* word-spacing: 100vw; */
  width: 250px;
}

.text__name {
  /* composes: grid__left-from-middle from global; */
  font-size: 36px;
  letter-spacing: 3.6px;
  color: black-dark;
  /* text-align: end; */
  position: absolute;
  /* left: -250px; */
}

.text__about {
  /* composes: grid__right-from-middle from global; */
  font-size: 18px;
  letter-spacing: 1.8px;
  color: white-full;
  margin-top: 70px;
  position: absolute;
}

@media medium {
  .text__about {
    margin-top: 120px;
  }

  .text__name {
    margin-top: 50px;
  }

  .image {
    object-position: 50px 40%;
  }
}

@media small {
  .image {
    object-position: 50% 30%;
  }

  .text__name {
    display: none;
    /* font-size: 5vw; */
    /* color: #d4cccc; */
    /* background-color: #afb1b7; */
    /* width: 100vw; */
    /* height: 50px; */
    /* text-align: center; */
    /* line-height: 50px; */
    /* border-right: 3px solid #000; */
    /* border-left: 3px solid #000; */
    /* margin: 0 50px; */
    /* text-transform: uppercase; */
  }

  .text__about {
    display: none;
    /* font-size: 5vw; */
    /* margin-top: 50px; */
    /* right: 0; */
    /* width: unset; */
  }
}
