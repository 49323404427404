@value small, medium, small-height from "styles/share/bp.css";

.vido__list {
  list-style: none;
  padding: 0 20px;
  margin: 0;
}

.carousel__box {
  display: none;
}

@media small {
  .vido__list {
    display: none;
  }

  .carousel__box {
    display: block;
    width: 100vw;
  }
}
