@value dark, white-full from "styles/share/colors.css";
@value lang-button-side-size: 50px;
@value small, medium, large from "styles/share/bp.css";

.lang__button {
  composes: button__empty button__link from global;
  font-family: 'Open Sans', sans-serif;
  color: white-full;
  background-color: dark;
  height: lang-button-side-size;
  width: lang-button-side-size;
}

.lang__container {
  position: fixed;
  top: 0;
  left: 86vw;
  display: flex;
  justify-content: space-between;
  width: calc(lang-button-side-size * 2 + 10px);
  flex-direction: row-reverse;
  z-index: 20;
}

@media medium {
  .lang__container {
    left: 80vw;
  }
}

@media small {
  .lang__container {
    right: 0;
    left: unset;
  }
}
