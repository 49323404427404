@value grey-cool, white-full, black-full, black-dark from "styles/share/colors.css";
@value small, medium, small-height from "styles/share/bp.css";

.container {
  background-color: white-full;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.image {
  max-height: 80vh;
  /* display: inline-block; */
  max-width: 40%;
  vertical-align: baseline;
  /* margin: 1%; */
  float: left;
  /* object-fit: contain; */
  /* object-position: top; */
  margin-right: 10px;
}

.separator {
  clear: both;
  height: 40px;
  background-color: grey-cool;
}

.text__title {
  /* font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-align: justify;
  font-size: 24px;
  font-style: normal;
  line-height: 1em;
  letter-spacing: 0.08333333333333333em;
  font-stretch: normal; */
  color: black-dark;
  margin: 0 50px;
  padding-top: 10px;
}

.text__body {
  font-family: 'Open Sans', sans-serif;
  text-align: justify;
  font-size: 12px;
  font-style: normal;
  line-height: 1em;
  color: black-dark;
  /* margin-right: 10px; */
}

.text__date {
  font-family: 'Open Sans', sans-serif;
  text-align: justify;
  font-size: 36px;
  font-style: normal;
  line-height: 1em;
  color: black-dark;
  margin: 10px;
}

.about p {
  text-indent: 30px;
}

.link {
  margin-left: 30px;
}

.map {
  /* position: absolute; */
  /* right: 0; */
  /* bottom: 0; */
  height: 300px;
  display: inline-block;
  margin: 1%;
  /* width: 33%; */
  vertical-align: top;
  float: right;
}

.head {
  text-align: center;
}

@media small-height {
  .image {
    max-height: 400px;
  }
}

@media small {
  .image {
    max-width: 100%;
    display: block;
    float: unset;
    margin: 0 auto;
  }

  .map {
    max-width: 100%;
  }
}
