@value grey-cool, white-full, black-full, black-dark from "styles/share/colors.css";
@value small, medium, large, x-large, small-height, medium-height from "styles/share/bp.css";
@value marg 2vw;

.container {
  padding: 2vw;
  text-decoration: none;
  display: flex;
  background-color: white-full;
  margin-bottom: 40px;
}

.image {
  height: 200px;
  margin-right: 2vw;
  align-self: center;
}

.text__container {
}

.text__title {
  font-family: 'Forum', sans-serif;
  font-weight: bold;
  font-size: 36px;
  font-style: normal;
  line-height: 1em;
  letter-spacing: 0.08333333333333333em;
  font-stretch: normal;
  color: black-dark;
  margin: 0;
}

.text__body {
  font-family: 'Open Sans', sans-serif;
  text-align: justify;
  font-size: 12px;
  font-style: normal;
  line-height: 1em;
  color: black-dark;
}

@media small {
  .container {
    flex-direction: column;
  }

  .text__title {
    text-align: center;
    margin-top: 10px;
  }

  .image {
    margin: 20px;
  }
}
