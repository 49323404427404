@value black-full, link-hover from "styles/share/colors.css";
@value small, medium, large, x-large, small-height, medium-height from "styles/share/bp.css";

.nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  height: 85vh;
  top: 0;
  z-index: -1;
  /* margin-left: 33%; */
  left: calc(50% - 63px);
}

.nav__link {
  composes: button__empty button__link from global;
  display: block;
  padding: 1rem 1rem;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.7;
  letter-spacing: 1.8px;
  color: black-full;
  text-align: left;
  text-decoration: none;
  /* text-transform: uppercase; */
}

.nav__link:focus {
  outline: none;
}

.nav__link_active {
  /* color: white; */
}

.button-social {
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin: 5px;
  padding: 0;
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.button-social__container {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 22vh;
  width: 100%;
  flex-wrap: wrap;
  /* text-align: center; */
}

.button-social:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.button-connect {
  text-decoration: none;
  display: none;
  vertical-align: middle;
  text-align: center;
}

.button-connect_email {
  padding: 4px;
}

.button-connect_phone {
  line-height: 46px;
}

.text-connect {
  /* width: 200px; */
  margin-bottom: 1vh;
}

.margin-bottom {
  margin-bottom: 60px;
}

.text__container {
  position: absolute;
  bottom: 1vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.link-social {
  height: 50px;
  margin-bottom: 4px;
}

@media medium, small {
  .button-connect {
    display: inline-block;
  }

  .text-connect {
    display: none;
  }

  .button-social__container {
    width: 120px;
    left: calc(50% - 60px);
    bottom: 20vh;
  }

  .nav {
    height: 75vh;
  }
}

@media medium-height {
  @media large, x-large {
    .button-social__container {
      width: 30%;
      bottom: 25vh;
      flex-direction: column;
    }
  }

  @media medium, small {
    .button-social__container {
      width: 100%;
      left: 0;
      bottom: 20vh;
    }

    .header {
      min-width: 250px;
    }
  }
}

@media small-height {
  .button-social__container {
    bottom: 20vh;
  }

  .nav {
    margin-bottom: 60px;
  }

  .margin-bottom {
    margin-bottom: 1vh;
  }

  @media medium, small {
    .button-social__container {
      width: 50px;
      left: 0;
      top: 52px;
      bottom: 0;
      flex-direction: column;
      justify-content: flex-start;
    }

    .button-social {
      margin: 2px 0;
    }

    .link-social {
      margin-bottom: 4px;
    }
  }
}
