@value black-full from "styles/share/colors.css";
@value small, medium, small-height, medium-height from "styles/share/bp.css";

.container {
  position: absolute;
  height: 36px;
  top: 10vh;
  width: 100%;
  text-align: center;
}

.listen-button {
  composes: button__empty from global;
  z-index: 30;
}

.listen-button__text {
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 10px;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.1em;
  color: black-full;
}

@media medium, small {
  .container {
    top: 7vh;
  }
}

@media medium-height {
  .container {
    position: absolute;
    width: 30%;
    top: 10vh;
    left: 0;
  }

  .container__marg {
    margin-top: 0;
  }

  @media medium, small {
    .container {
      position: absolute;
      top: 10px;
      right: 10px;
      left: unset;
    }
  }
}
