.header__text {
  margin: 5px 0;
  text-align: center;
}

.overlay {
  z-index: 201;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border: 1px solid #ccc;
  overflow: auto;
  background-color: #282f31;
  padding-top: 2em;
}

.close {
  color: white;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 2em;
  padding: 0 10px;
  line-height: 1em;
}
