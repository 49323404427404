/* autoprefixer grid: on */

.video-list {
  cursor: pointer;
}

.video-list:hover {
  color: blue;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.swipeItem {
  position: relative;
  overflow: hidden;
  width: 350px;
  outline: 1px solid black;
}

.swipeItem-content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.swipeList {
  list-style-type: none;
}

.swipeList-addButton {
  font-size: 30px;
  text-align: center;
  width: 350px;
  height: 65px;
  padding: auto;
  background-color: white;
  border: none;
  outline: 1px solid black;
}

.element_hide {
  display: none;
}
