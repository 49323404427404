@value small, medium, large from "styles/share/bp.css";

.arrow {
  composes: button__empty from global;
  height: 40vh;
  width: 20vw;
}

.arrow_left {
  transform: rotate(180deg);
}

.arrow_right {
}

.item {
  /* width: 30%; */
  margin: auto;
  padding: 20px;
}

.item_visible {
}

.item_invisible {
}

.item_center {
  /* transform: scale(1.1); */
  /* transition: width 2s, height 2s, background-color 2s, transform 2s; */
  /* transform: translate(-21px, 0); */
}

.item_side {
  transform: scale(0.5);
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
}

.items {
  display: block;
}

.root {
  padding: 0 20vw;
  margin: 0 10px;
}

.slide {
  /* padding: 24px 16px; */
  color: #fff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  transition: 0.5s;
  overflow: hidden;
  height: 100%;
}

.slide_middle {
  opacity: 1;
  transform: scale(1);
}

.slide_side {
  opacity: 0.9;
  transform: scale(0.7);
}

@media small {
  .root {
    padding: 0;
    margin: 0;
  }

  .container_pad {
    padding: 0 10px;
  }
}
